<template>
  <Popup v-model:show="showPopup">
    <template #content>
      <div class="popup">
        <div class="popup_wrapper">
          <div class="popup_gradient"></div>
          <div class="popup_heading">
            <div class="popup_heading-icon">
              <img src="../../img/icons/popup/hello.svg" alt="icon">
            </div>
            <h3 class="popup_heading-title">Contact with us</h3>
            <p class="popup_heading-desc">If you have any issues or questions, please contact
              us on Telegram or using the form below.</p>
          </div>
          <ul class="social">
            <li class="social_item">
              <a href="https://t.me/patso_o" target="_blank">
                <img src="../../img/icons/social/telegram.svg" alt="telegram">
                Write to us on Telegram
              </a>
            </li>
          </ul>
          <div class="popup_separator">
            <span>OR</span>
          </div>
          <div class="popup_form">
            <FormInput label="E-mail address" placeholder="Enter your email..." type="email"
            v-model="email" :isError="emailError"/>
            <FormTextarea label="Your message" placeholder="Enter message..."
            v-model="message" :isError="messageError"/>
            <p v-if="errorMessage" :class="{ 'popup_error': errorMessage }">{{ errorMessage }}</p>
            <button class="btn black" @click="sendMessage">Send message</button>
            <div class="popup_form-text">
              We will respond within 24 hours
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { ref } from 'vue';
import { useShowPopupLogic, useShowPropDeclaration } from './utilities/showPropLogic';

import Popup from './Popup.vue';
import FormInput from '../form/FormInput.vue';
import FormTextarea from '../form/FormTextarea.vue';

export default {
  name: 'PopupLogin',
  components: {
    Popup,
    FormInput,
    FormTextarea,
  },
  useShowPropDeclaration,
  setup(props, { emit }) {
    const showPopup = useShowPopupLogic(props, emit);
    const message = ref('');
    const email = ref('');

    const errorMessage = ref('');
    const emailError = ref(false);
    const messageError = ref(false);

    const sendMessage = () => {
      errorMessage.value = '';
      emailError.value = false;
      messageError.value = false;

      if (!email.value || email.value.trim() === '') {
        errorMessage.value = 'The Email field cannot be empty.';
        emailError.value = true;
        return;
      }
      if (!message.value || message.value.trim() === '') {
        errorMessage.value = 'The Message field cannot be empty.';
        messageError.value = true;
        return;
      }
      errorMessage.value = 'The contact form is temporarily unavailable. Please contact us on Telegram.';
      email.value = '';
      message.value = '';
    };

    const showRegister = () => {
      emit('showRegister');
    };
    return {
      showPopup, showRegister, sendMessage, email, message, errorMessage, emailError, messageError,
    };
  },
};

</script>

<style scoped lang="scss">
.popup_form {
  .btn {
    margin-top: 1.6rem;
  }
}
.social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  &_item {
    width: 100%;
    border: .1rem solid $border-gray;
    @include text-14-medium;
    border-radius: .8rem;
    cursor: pointer;
    transition: .5s;
    &:hover {
      opacity: .6;
      transition: .5s;
    }
    img {
      width: 2rem;
      margin-right: 1.2rem;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.4rem;
      width: 100%;
    }
  }
}
</style>
