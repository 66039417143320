export default function formatNumber(count) {
  if (!count && count !== 0) return '';

  if (count < 1000) {
    return count.toString();
  }
  if (count < 1000000) {
    const thousands = count / 1000;
    if (thousands % 1 === 0) {
      return `${thousands.toFixed(0)}k`;
    }
    return `${thousands.toFixed(1).replace('.', ',')}k`;
  }
  const millions = count / 1000000;
  if (millions % 1 === 0) {
    return `${millions.toFixed(0)}m`;
  }
  return `${millions.toFixed(1).replace('.', ',')}m`;
}
