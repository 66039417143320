<template>
  <div class="section">
    <div class="section_header">
      <p>Who to follow</p>
      <router-link to="/communities">See all</router-link>
    </div>
    <ul class="section_list">
      <li class="author" v-for="user in displayedUsers" :key="user.id">
        <router-link :to="`/${user.username}`">
          <img :src="user.photo" :alt="user.name"
          class="author_img">
          <div class="author_data">
            <div class="author_data-name">
              <p>{{ user.name }}</p>
              <img src="../img/icons/post/verified.svg" alt="verified" v-if="user.verified">
            </div>
            <span>{{ user.about }}</span>
          </div>
        </router-link>
        <button class="author_action" @click="showLogin = true">
          <img src="../img/icons/follow.svg" alt="">
        </button>
      </li>
    </ul>
  </div>
  <PopupLogin
    :title="`Log in to follow`"
    :desc="`Follow other wepats users to stay updated on their posts, updates, and activity.`"
    :color="'yellow'"
    :icon="'Follow'"
    v-model:show="showLogin"/>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import users from '../functions';

import PopupLogin from './popup/PopupLogin.vue';

export default {
  name: 'WhoToFollow',
  components: {
    PopupLogin,
  },
  setup() {
    const usersData = ref(users.value);
    const displayedUsers = ref([]);

    const showLogin = ref(false);

    // eslint-disable-next-line
    const recommendedUsers = computed(() => usersData.value.filter((user) => user.recommended === true));

    const shuffleAndSliceUsers = () => {
      const shuffledUsers = [...recommendedUsers.value];
      // eslint-disable-next-line
      for (let i = shuffledUsers.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledUsers[i], shuffledUsers[j]] = [shuffledUsers[j], shuffledUsers[i]];
      }
      displayedUsers.value = shuffledUsers.slice(0, 5);
    };

    onMounted(() => {
      shuffleAndSliceUsers();
    });

    return {
      displayedUsers, showLogin,
    };
  },
};
</script>
<style lang="scss">
.author {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    display: flex;
    align-items: center;
  }
  &_img {
    width: 4rem;
    height: 4rem;
    position: relative;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 1.2rem;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_data {
    display: flex;
    flex-direction: column;
    &-name {
      display: flex;
      align-items: center;
      @include text-15-semibold;
      img {
        width: 1.6rem;
        margin-left: .4rem;
      }
    }
    span {
      @include text-13-regular;
      color: $text-gray;
    }
  }
  &_action {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .5s;
    background-color: transparent;
    &:hover {
      opacity: .6;
      transition: .5s;
    }
    img {
      width: 2rem;
    }
  }
}
</style>
