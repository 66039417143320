<template>
    <var-popup v-model:show="showPopup" :position="popupPosition" @open="onOpen">
      <slot name="content"></slot>
    </var-popup>
  </template>

<script>
import {
  ref, computed, onMounted, onUnmounted,
} from 'vue';

export default {
  name: 'PopupStructure',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    onOpen: {
      type: Function,
      default: () => {},
    },
    alwaysCenter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:show'],
  setup(props, { emit }) {
    const showPopup = computed({
      get: () => props.show,
      set: (value) => emit('update:show', value),
    });

    const isMobilePopup = ref(window.innerWidth < 480);

    const popupPosition = computed(() => {
      if (props.alwaysCenter) {
        return 'center';
      }
      return isMobilePopup.value ? 'bottom' : 'center';
    });

    const handleResize = () => {
      isMobilePopup.value = window.innerWidth < 480;
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      showPopup,
      popupPosition,
    };
  },
};
</script>

<style scoped>
</style>
