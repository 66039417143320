<template>
    <div class="nav">
      <div class="nav_group">
        <ul class="nav_links">
          <li>
            <router-link to="/" class="nav_links-item" :class="{ active: $route.path === '/' }">
              <img src="../img/icons/sidebar/recommended.svg" alt="recommended">
              Recommended
            </router-link>
          </li>
          <li>
            <router-link to="/popular" class="nav_links-item"
            :class="{ active: $route.path === '/popular' }">
              <img src="../img/icons/sidebar/popular.svg" alt="popular">
              Popular
            </router-link>
          </li>
          <li @click="showLogin = true" @keypress="showLogin">
            <div class="nav_links-item">
              <img src="../img/icons/sidebar/following.svg" alt="following">
              Following
            </div>
          </li>
      </ul>
      </div>
      <div class="nav_group">
        <span>explore</span>
        <ul class="nav_links">
          <li>
            <router-link to="/communities" class="nav_links-item"
            :class="{ active: $route.path === '/communities' }">
              <img src="../img/icons/sidebar/community.svg" alt="community">
              Communities
            </router-link>
          </li>
          <li>
            <router-link to="/people" class="nav_links-item"
            :class="{ active: $route.path === '/people' }">
              <img src="../img/icons/sidebar/people.svg" alt="people">
              People
            </router-link>
          </li>
      </ul>
      </div>
      <div class="nav_group">
        <span>other</span>
        <ul class="nav_links">
          <!-- <li>
            <router-link to="/" class="nav_links-item">
              <img src="../img/icons/sidebar/about.svg" alt="about">
              About
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/" class="nav_links-item">
              <img src="../img/icons/sidebar/blog.svg" alt="blog">
              Features
            </router-link>
          </li> -->
          <!-- <li>
            <router-link to="/" class="nav_links-item">
              <img src="../img/icons/sidebar/help.svg" alt="help">
              Help Center
            </router-link>
          </li> -->
          <li @click="showInvite = true" @keypress="showInvite">
            <div class="nav_links-item">
              <img src="../img/icons/sidebar/link.svg" alt="link">
              Invite Friends
            </div>
          </li>
          <li @click="showContact = true" @keypress="showContact">
            <div class="nav_links-item">
              <img src="../img/icons/sidebar/press.svg" alt="press">
              Contact Us
            </div>
          </li>
      </ul>
      </div>
    </div>
  <PopupLogin
    :title="`Log in to view your feed`"
    :desc="`You can create posts that will be visible to all wepats users.
    Share your thoughts, stories, and favorite moments!`"
    :color="'purple'"
    :icon="'Following'"
    v-model:show="showLogin"/>
  <PopupInvite :title="'Invite friends'"  v-model:show="showInvite" />
  <PopupContact v-model:show="showContact" />
</template>
<script>
import {
  ref, inject, onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import PopupLogin from './popup/PopupLogin.vue';
import PopupInvite from './popup/PopupInvite.vue';
import PopupContact from './popup/PopupContact.vue';

export default {
  name: 'SidebarLeft',
  components: {
    PopupLogin,
    PopupInvite,
    PopupContact,
  },
  setup() {
    const showLogin = ref(false);
    const showInvite = ref(false);
    const showContact = ref(false);

    const closeTabletSidebar = inject('closeTabletSidebar');
    const router = useRouter();

    onMounted(() => {
      router.afterEach((to, from) => {
        if (to.path !== from.path) {
          closeTabletSidebar();
        }
      });
    });

    return {
      showLogin,
      showInvite,
      showContact,
    };
  },
};
</script>
<style lang="scss">
.nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  .nav_group {
    border-bottom: 0.06rem solid $border-gray;
    margin-bottom: 1.6rem;
    span {
      display: block;
      padding-left: 1.2rem;
      margin-bottom: .8rem;
      color: $text-gray;
      @include text-11-semibold;
      text-transform: uppercase;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}
.nav_links {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.2rem;
  li {
    width: 100%;
  }
  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.2rem;
    border-radius: .8rem;
    @include text-14-regular;
    transition: .5s;
    cursor: pointer;
    &:hover {
      transition: .5s;
      background: $gray-bg-hover;
    }
    img {
      width: 2rem;
      margin-right: 1.2rem;
    }
    &.active {
      background-color: $gray-bg;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}
</style>
