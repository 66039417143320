<template>
  <div class="top">
    <Header />
  </div>
  <div class="container">
    <div class="sidebar" :class="{ 'open': isTabletOpen }"
    v-if="!isTablet || (isTablet && isTabletOpen)" @click.stop>
      <Sidebar />
    </div>
    <div class="content">
      <router-view/>
    </div>
    <div class="suggested">
      <component :is="suggestedComponent" />
      <Footer />
    </div>
  </div>
  <div class="overlay" v-if="isTabletOpen" @click="closeTabletSidebar"
  @keypress="closeTabletSidebar"></div>
</template>

<script>
import {
  ref, computed, provide, onMounted, onUnmounted,
} from 'vue';
import { useRoute } from 'vue-router';

import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import WhoToFollow from '@/components/WhoToFollow.vue';
import HotNews from '@/components/HotNews.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'RecommendedNews',
  components: {
    Sidebar,
    Header,
    WhoToFollow,
    HotNews,
    Footer,
  },
  setup() {
    const route = useRoute();

    const isTabletOpen = ref(false);
    const isTablet = ref(false);
    const isMobile = ref(false);

    const suggestedComponent = computed(() => {
      const routeName = route.name;
      const hotNewRoutes = ['Communities', 'People'];
      return hotNewRoutes.includes(routeName) ? 'HotNews' : 'WhoToFollow';
    });

    const toggleMobileSidebar = () => {
      isTabletOpen.value = !isTabletOpen.value;

      // Prevent scrolling on body when sidebar is open
      if (isTabletOpen.value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    };

    // Close mobile sidebar
    const closeTabletSidebar = () => {
      if (isTabletOpen.value) {
        isTabletOpen.value = false;
        document.body.style.overflow = '';
      }
    };

    // Check window size on mount and resize
    const checkWindowSize = () => {
      const windowWidth = window.innerWidth;
      isMobile.value = windowWidth <= 880;
      isTablet.value = windowWidth > 880 && windowWidth <= 1080;

      // If screen resizes to desktop while sidebar is open, close it
      if (!isTablet.value && isTabletOpen.value) {
        closeTabletSidebar();
      }
    };

    onMounted(() => {
      checkWindowSize();
      window.addEventListener('resize', checkWindowSize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', checkWindowSize);
      document.body.style.overflow = '';
    });

    // Provide values to be used by child components
    provide('isTabletOpen', isTabletOpen);
    provide('toggleMobileSidebar', toggleMobileSidebar);
    provide('closeTabletSidebar', closeTabletSidebar);
    provide('isTablet', isTablet);
    provide('isMobile', isMobile);

    return {
      suggestedComponent,
      isTabletOpen,
      isTablet,
      closeTabletSidebar,
    };
  },
};
</script>

<style lang="scss">
.top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.sidebar {
  width: 22rem;
  flex-shrink: 0;
  position: sticky;
  height: 100%;
  top: 7.6rem;
  @media (max-width: 1180px) {
    width: 20rem;
  }
  @media (max-width: 1080px) {
    position: fixed;
    padding: 1.6rem 1.2rem;
    top: 6rem;
    left: -28rem;
    width: 28rem;
    height: 100vh;
    background: $white-bg;
    z-index: 1001;
    transition: transform 0.3s ease;
  }
  &.open {
    transform: translateX(28rem);
    transition: transform 0.3s ease;
  }
}

.content {
  flex: 1;
  max-width: 63.2rem;
  margin: 0 2rem;
  border-left: 0.06rem solid $border-gray;
  border-right: 0.06rem solid $border-gray;
  min-height: 100vh;
  @media (max-width: 1080px) {
    max-width: 100%;
    margin: 0 2rem 0 0;
    border-left: none;
  }
  @media (max-width: 880px) {
    margin: 0;
  }
}

.suggested {
  width: 29rem;
  flex-shrink: 0;
  height: 69rem;
  position: sticky;
  height: 100%;
  top: 7.6rem;
  @media (max-width: 880px) {
    display: none;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}
</style>
