<template>
  <div class="textarea">
    <label class="textarea_label" v-if="label" :for="textareaId">
      {{ label }}
      <div class="textarea_wrapper" :class="{ 'error': isError }">
        <textarea
          :id="textareaId"
          :placeholder="placeholder"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        ></textarea>
      </div>
    </label>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'TextareaComponent',
  props: {
    label: String,
    placeholder: String,
    isError: Boolean,
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const textareaId = computed(() => `textarea-${Math.random().toString(36).substring(2, 15)}`);

    return {
      textareaId,
    };
  },
};
</script>

<style scoped lang="scss">
.textarea {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
  &_label {
    @include text-14-medium;
  }
  &_wrapper {
    display: flex;
    align-items: flex-start;
    border: .1rem solid $border-gray;
    margin-top: .4rem;
    border-radius: .8rem;
    @include text-14-regular;
    padding: 1.2rem;
    transition: .5s;
    &.error {
      border: .1rem solid $border-red;
    }
    textarea {
      width: 100%;
      border: none;
      resize: vertical;
      min-height: 6rem;
      outline: none;
    }
    ::placeholder {
      color: $text-placeholder;
      @include text-14-regular;
    }
    &:focus-within {
      border-color: $border-blue;
      transition: .5s;
    }
  }
}
</style>
