import { computed } from 'vue';

export const useShowPropDeclaration = {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:show'],
};

export function useShowPopupLogic(props, emit) {
  const showPopup = computed({
    get: () => props.show,
    set: (value) => emit('update:show', value),
  });

  return showPopup;
}
