<template>
    <div class="search" ref="searchContainer">
      <div class="search_input">
        <img src="../img/icons/search.svg" alt="search">
        <label for="searchQuery">
          <input type="text" v-model="searchQuery" placeholder="Search" autocomplete="off">
        </label>
      </div>
      <button v-if="searchQuery" @click="clearSearch" class="search_clear">
        <img src="../img/icons/header/close.svg" alt="close">
      </button>
      <div class="search_results" v-if="filteredUsersPeople.length > 0 ||
      filteredUsersCommunity.length > 0" ref="searchResults">
        <div v-if="filteredUsersPeople.length > 0" class="search_results-block">
          <div class="search_results-heading">People</div>
          <ul class="search_results-list">
            <li v-for="user in limitedFilteredUsersPeople" :key="user.id">
              <router-link :to="`${user.username}`" class="result">
                <div class="result_photo">
                  <img :src="user.photo" alt="user photo">
                </div>
                <div class="result_data">
                  <p>{{ user.name }}</p>
                  <span>@{{user.username}}</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
        <div v-if="filteredUsersCommunity.length > 0" class="search_results-block">
          <div class="search_results-heading">Community</div>
          <ul class="search_results-list">
            <li v-for="user in limitedFilteredUsersCommunity" :key="user.id">
              <router-link :to="`${user.username}`" class="result">
                <div class="result_photo">
                  <img :src="user.photo" alt="user photo">
                </div>
                <div class="result_data">
                  <p>{{ user.name }}</p>
                  <span>{{user.followers}} followers</span>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div v-else-if="searchQuery" class="search_results">
        No results
      </div>
    </div>
  </template>

<script>
import {
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import users from '../functions';

export default {
  name: 'SearchComponent',
  setup() {
    const searchQuery = ref('');
    const searchContainer = ref(null);
    const searchResults = ref(null);

    const filteredUsersPeople = computed(() => {
      if (!searchQuery.value) {
        return [];
      }

      const query = searchQuery.value.toLowerCase();
      return users.value.filter((user) => {
        const nameMatch = user.name.toLowerCase().includes(query);
        const usernameMatch = user.username.toLowerCase().includes(query);
        return (nameMatch || usernameMatch) && !user.community;
      });
    });

    const filteredUsersCommunity = computed(() => {
      if (!searchQuery.value) {
        return [];
      }

      const query = searchQuery.value.toLowerCase();
      return users.value.filter((user) => {
        const nameMatch = user.name.toLowerCase().includes(query);
        const usernameMatch = user.username.toLowerCase().includes(query);
        return (nameMatch || usernameMatch) && user.community;
      });
    });

    const limitedFilteredUsersPeople = computed(() => filteredUsersPeople.value.slice(0, 4));

    const limitedFilteredUsersCommunity = computed(() => filteredUsersCommunity.value.slice(0, 4));

    const clearSearch = () => {
      searchQuery.value = '';
    };

    const handleClickOutside = (event) => {
      if (searchContainer.value && !searchContainer.value.contains(event.target)) {
        searchQuery.value = ''; // Закриваємо результати пошуку
      }
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });

    return {
      searchQuery,
      filteredUsersPeople,
      filteredUsersCommunity,
      clearSearch,
      searchContainer,
      searchResults,
      limitedFilteredUsersPeople,
      limitedFilteredUsersCommunity,
    };
  },
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 32rem;
  height: 4rem;
  border-radius: .8rem;
  border: .06rem solid $border-gray;
  background-color: $gray-bg;
  padding: 0 1.2rem;
  @include text-14-regular;
  position: relative;
  transition: .5s;
  &:hover {
    transition: .5s;
    background-color: $search-bg-hover;
  }
  &_input {
    display: flex;
    align-items: center;
    width: 100%;
    color: $text-gray;
    height: 100%;
    label {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
    }
    input {
      height: 100%;
      width: 100%;
      background: none;
      border: 0;
      -webkit-appearance: none;
      appearance: none;
      margin-top: .1rem;
    }
    img {
      width: 2rem;
      margin-right: 1.2rem;
    }
  }
  &_clear {
    background: none;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    &:hover {
      opacity: .6;
      transition: .5s;
    }
    img {
      width: 1.6rem;
    }
  }
  &_results {
    border-radius: .8rem;
    padding: 1.2rem 1.6rem;
    position: absolute;
    top: 4.4rem;
    left: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
    &-heading {
      @include text-14-semibold;
      color: $text-black;
      margin-bottom: 1.2rem;
    }
    &-block {
      margin-bottom: 1.6rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-list {
      li {
        margin-bottom: 1.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.result {
  display: flex;
  align-items: center;
  &_photo {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    position: relative;
    margin-right: 1.2rem;
    border-radius: 100%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &_data {
    display: flex;
    flex-direction: column;
    p {
      @include text-15-semibold;
    }
    span {
      @include text-13-regular;
      color: $text-gray;
    }
  }
}
</style>
