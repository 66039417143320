import { createRouter, createWebHistory } from 'vue-router';

import users from '@/functions';
import posts from '@/constants/posts';
import RouteName from './routeName';

const isValidUser = (username) => users.value.some((user) => user.username === username);

const routes = [
  {
    path: '/',
    name: RouteName.Recommended,
    component: () => import('../views/Recommended.vue'),
    meta: {
      title: () => 'Recommended',
    },
  },
  {
    path: '/popular',
    name: RouteName.Popular,
    component: () => import('../views/Popular.vue'),
    meta: {
      title: () => 'Popular',
    },
  },
  {
    path: '/communities',
    name: RouteName.Communities,
    component: () => import('../views/Communities.vue'),
    meta: {
      title: () => 'Communities',
    },
  },
  {
    path: '/people',
    name: RouteName.People,
    component: () => import('../views/People.vue'),
    meta: {
      title: () => 'People',
    },
  },
  {
    path: '/:username/post/:id',
    name: RouteName.PostView,
    component: () => import('../views/PostView.vue'),
    beforeEnter: (to, from, next) => {
      const { username, id } = to.params;
      const userExists = users.value.some((user) => user.username === username);
      const postId = Number(id);

      if (userExists) {
        const foundUser = users.value.find((user) => user.username === username);
        const postExists = foundUser.posts && foundUser.posts.some((post) => post.id === postId);
        if (postExists) {
          next();
        } else {
          next('/not-found');
        }
      } else {
        next('/not-found');
      }
    },
    meta: {
      title: (route) => {
        const { username, id } = route.params;
        const foundUser = users.value.find((user) => user.username === username);
        const foundPost = posts.value.find(
          (post) => post.id === Number(id) && post.userId === foundUser.id,
        );
        if (foundPost) {
          return foundPost.title ? foundPost.title : foundPost.text;
        }
        return 'Post not found';
      },
    },
  },
  {
    path: '/:username',
    name: RouteName.Profile,
    component: () => import('../views/Profile.vue'),
    beforeEnter: (to, from, next) => {
      const { username } = to.params;
      const reservedRoutes = ['popular', 'communities', 'people', 'post'];

      if (isValidUser(username) && !reservedRoutes.includes(username)) {
        next();
      } else if (reservedRoutes.includes(username)) {
        next();
      } else {
        next('/not-found');
      }
    },
    meta: {
      title: (route) => {
        const { username } = route.params;
        const foundUser = users.value.find((user) => user.username === username);
        return foundUser ? `${foundUser.name}` : 'Profile';
      },
    },
  },
  {
    path: '/not-found',
    name: RouteName.NotFound,
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: () => 'Not Found',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/not-found',
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title(to);
  } else {
    document.title = 'wepats';
  }
  next();
});

export default router;
