const RouteName = {
  Recommended: 'Recommended',
  RecommendedTest: 'RecommendedTest',
  Popular: 'Popular',
  PostView: 'PostView',
  Profile: 'Profile',
  Communities: 'Communities',
  People: 'People',
  NotFound: 'NotFound',
};
export default RouteName;
