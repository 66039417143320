import { createApp } from 'vue';
import App from './App.vue';

// eslint-disable-next-line
import Varlet from '@varlet/ui';
import '@varlet/ui/es/style';

import '@/css/main.scss';

import router from './router';
import store from './store';
// eslint-disable-next-line
import VueGtag from 'vue-gtag-next';

const app = createApp(App);

app.use(store);
app.use(router);

app.use(VueGtag, {
  property: {
    id: 'G-T4GCWYEB20',
  },
});

app.use(Varlet);

app.mount('#app');
