<template>
  <Popup v-model:show="showPopup">
    <template #content>
      <div class="popup">
        <div class="popup_wrapper">
          <div class="popup_gradient purple"></div>
          <div class="popup_heading">
            <div class="popup_heading-icon purple">
              <img src="../../img/icons/popup/link.svg" alt="icon">
            </div>
            <h3 class="popup_heading-title">{{ title }}</h3>
            <p class="popup_heading-desc">Invite friends via social media or copy the
              invitation link.</p>
          </div>
          <ul class="share">
            <li class="share_item" @click="shareOnFacebook" @keypress="shareOnFacebook">
              <img src="../../img/icons/social/facebook.svg" alt="facebook">
              Send an invitation to Facebook
            </li>
            <li class="share_item" @click="shareOnTelegram" @keypress="shareOnTelegram">
              <img src="../../img/icons/social/telegram.svg" alt="telegram">
              Send an invite to Telegram
            </li>
            <li class="share_item" @click="shareOnVk" @keypress="shareOnVk">
              <img src="../../img/icons/social/vk.svg" alt="vk">
              Send an invitation to VK
            </li>
          </ul>
          <div class="popup_separator">
            <span>OR</span>
          </div>
          <div class="popup_form">
            <button class="btn black" @click="copyLink">{{ buttonText }}</button>
            <div class="popup_form-text">
              To learn more about the referral program, visit our
              <a href="/help">Help Center</a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { ref } from 'vue';
import { useShowPopupLogic, useShowPropDeclaration } from './utilities/showPropLogic';

import Popup from './Popup.vue';

export default {
  name: 'PopupInvite',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    Popup,
  },
  useShowPropDeclaration,
  setup(props, { emit }) {
    const showPopup = useShowPopupLogic(props, emit);
    const buttonText = ref('Copy invite link');

    const showInvite = () => {
      emit('showInvite'); // Emit event to parent component
    };

    const shareOnFacebook = () => {
      const message = encodeURIComponent('Hello, welcome to wepats.com');
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${message}`, '_blank');
    };

    const shareOnTelegram = () => {
      const message = encodeURIComponent('Hello, welcome to wepats.com');
      window.open(`https://t.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${message}`, '_blank');
    };

    const shareOnVk = () => {
      const message = encodeURIComponent('Hello, welcome to wepats.com');
      window.open(`http://vk.com/share.php?url=${encodeURIComponent(window.location.href)}&title=${message}`, '_blank');
    };

    const copyLink = () => {
      navigator.clipboard.writeText('wepats.com').then(() => {
        buttonText.value = 'Link copied!';
        setTimeout(() => {
          buttonText.value = 'Copy invite link';
        }, 2000);
      });
    };
    return {
      showPopup, showInvite, copyLink, buttonText, shareOnFacebook, shareOnTelegram, shareOnVk,
    };
  },
};

</script>

<style scoped lang="scss">
.share {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.4rem;
    width: 100%;
    border: .1rem solid $border-gray;
    @include text-14-medium;
    border-radius: .8rem;
    cursor: pointer;
    transition: .5s;
    &:hover {
      opacity: .6;
      transition: .5s;
    }
    img {
      width: 2rem;
      margin-right: 1.2rem;
    }
  }
}
</style>
