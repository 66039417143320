import { ref } from 'vue';

const posts = ref([
  // PATSO - 50050
  {
    id: 50050,
    userId: 1,
    type: 'Recommended',
    position: 1,
    date: '2020-03-14T13:30:00',
    title: 'The end of the Imperium',
    text: 'Dear wepats community,\n\nToday, after four years of continuous work, we have made the decision to temporarily suspend new user registrations on the wepats platform.\n\nWe will definitely be back. Thank you for staying with us! 🫡',
    // eslint-disable-next-line
    media: require('../img/media/patso.png'),
    repostCount: 234980,
    commentsCount: 143900,
    viewsCount: 22679091,
    reactions: [
      { reactionId: 4, count: 2400902 },
      { reactionId: 2, count: 1100900 },
      { reactionId: 5, count: 400200 },
      { reactionId: 1, count: 12399 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user36.png'),
        authorName: 'James Anderson',
        date: '2020-03-14T14:12:00',
        text: 'This is heartbreaking…\nwepats was my favorite place to connect with amazing people. I’ll truly miss this community ',
        likesCount: 24780,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user5.png'),
        authorName: 'Mia Rodriguez',
        date: '2020-03-14T16:23:00',
        text: 'This is such sad news. And on top of that, a global pandemic just started… What a day?!? 🫠🫠',
        likesCount: 8481,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user48.png'),
        authorName: 'Egor Ivanov',
        date: '2020-03-14T20:01:00',
        text: 'I believe this is just temporary, and wepats will be back soon!',
        likesCount: 3100,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user47.png'),
        authorName: 'Sophia Carter',
        date: '2020-03-14T19:21:00',
        text: 'Will we still be able to use the platform? What restrictions will apply to existing users?',
        likesCount: 1040,
      },
    ],
  },
  {
    id: 50049,
    userId: 1,
    date: '2020-03-10T10:12:02',
    title: 'We moved to Krakow',
    text: 'The wepats headquarters is now located in Krakow. You are always welcome to visit and have a coffee.',
    repostCount: 4781,
    commentsCount: 7900,
    viewsCount: 732900,
    reactions: [
      { reactionId: 3, count: 8321 },
      { reactionId: 2, count: 98000 },
      { reactionId: 1, count: 26789 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user48.png'),
        authorName: 'Egor Ivanov',
        date: '2020-03-10T13:28:00',
        text: 'Hope you guys did a proper onboarding for the local pigeons. They basically run the place.',
        likesCount: 6789,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user45.png'),
        authorName: 'Benjamin Carter',
        date: '2020-03-11T19:01:00',
        text: 'Now every employee will have to be issued a machete',
        likesCount: 12061,
      },
      {
        authorName: 'Olivia Hayes',
        date: '2020-03-13T23:11:00',
        text: 'If you want to learn about the local lore, I highly recommend following this community: https://t.me/cracow_fuck',
        likesCount: 892,
      },
    ],
  },
  {
    id: 50048,
    userId: 1,
    date: '2020-03-01T10:05:02',
    title: '5 updates this spring',
    text: '- You can now disable comments on a single post;\n\n- For each post, you can choose the reactions with which users can rate the post;\n\n- Animated avatars. Just upload a video instead of a photo;\n\n- Your own news feeds;\n\n- Quick view of attached links to posts;',
    repostCount: 4781,
    commentsCount: 7900,
    viewsCount: 1491081,
    reactions: [
      { reactionId: 5, count: 345 },
      { reactionId: 2, count: 12345 },
      { reactionId: 1, count: 45109 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user44.png'),
        authorName: 'Ethan Reynolds',
        date: '2020-03-02T21:51:00',
        text: 'I wish the emoji list was way bigger and customizable for each post.”',
        likesCount: 1311,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user40.png'),
        authorName: 'Lucas Bennett',
        date: '2020-03-01T10:56:00',
        text: 'I’d love to finally have the option to embed posts on my website. It would make interaction so much easier… and honestly, it’d be some great free promo for wepats.',
        likesCount: 721,
      },
      {
        authorName: 'Ava Mitchell',
        date: '2020-03-04T23:11:00',
        text: 'I’m curious—when will it be possible to add another account?',
        likesCount: 190,
      },
    ],
  },
  // N E W S - 49050
  {
    id: 49050,
    userId: 13,
    type: 'Recommended',
    position: 3,
    date: '2020-03-14T08:00:00',
    title: '🦠 A lot of morning news about Covid-19:',
    text: '• Infected – 168,768 (+12,372);\n• Deaths – 6,510 (+677);\n• Recovered – 77,257 (+3,289);\n• Main outbreak areas: China – 81,020 confirmed cases, 3,217 deaths. Italy – 24,747/1,809. Iran – 13,938/724. South Korea – 8,162/75. Spain – 7,844/292;\n• The mayors of New York and Los Angeles have ordered the closure of restaurants, cafes, bars, theaters, and cinemas. Now all food is delivery-only, and in NYC alone, there are over 50,000 restaurants employing more than 800,000 people;\n• U.S. authorities have nearly slashed the key interest rate to zero;\n• The Czech Republic has declared a nationwide quarantine;\n• Today, the first participant in a clinical trial for a coronavirus vaccine will receive an experimental dose.',
    repostCount: 324091,
    viewsCount: 3456981,
    reactions: [
      { reactionId: 1, count: 50 },
      { reactionId: 2, count: 11891 },
      { reactionId: 3, count: 113 },
      { reactionId: 4, count: 45123 },
      { reactionId: 5, count: 6512 },
    ],
  },
  {
    id: 49049,
    userId: 13,
    type: 'Popular',
    position: 2,
    category: 'Games',
    date: '2020-03-13T22:00:00',
    title: 'Pokémon Go adapts to the pandemic',
    text: '💪🏻 Due to Covid-19, the creators of Pokémon Go allowed players to catch Pokémon without leaving home — to maintain isolation.\n\nThe key feature of the mobile game is walking around the streets in search of new creatures, but during a pandemic, this is not very safe. The studio also increased the drop rate of certain items and introduced discounts on in-game purchases. They emphasized that these changes aim to enhance user safety.',
    repostCount: 42345,
    viewsCount: 2567912,
    reactions: [
      { reactionId: 1, count: 24900 },
      { reactionId: 2, count: 2311 },
      { reactionId: 3, count: 10761 },
      { reactionId: 4, count: 781 },
      { reactionId: 5, count: 119 },
    ],
  },
  {
    id: 49048,
    userId: 13,
    type: 'Popular',
    position: 5,
    category: 'Traveling',
    date: '2020-03-13T20:00:00',
    title: '🦠 New restrictions from the EU',
    text: 'The EU has imposed a 30-day ban on all but emergency travel to its territory',
    repostCount: 74912,
    viewsCount: 2912091,
    reactions: [
      { reactionId: 1, count: 109 },
      { reactionId: 2, count: 2611 },
      { reactionId: 3, count: 45 },
      { reactionId: 4, count: 12341 },
      { reactionId: 5, count: 2091 },
    ],
  },
  // K R A K O W - 48050
  {
    id: 48050,
    userId: 2,
    type: 'Popular',
    position: 8,
    date: '2020-03-13T20:19:00',
    category: 'Health',
    title: 'New COVID-19 Restrictions in Krakow',
    text: 'Due to the recent surge in COVID-19 cases, the city of Krakow is implementing new restrictions to protect public health. These measures include limited social gatherings, capacity reductions in indoor venues, and stricter mask mandates. We urge everyone to follow the guidelines and stay safe during this challenging time.\n\nLet’s all do our part to keep Krakow safe and healthy.',
    viewsCount: 12890,
  },
  // O X X X Y M I R O N - 47050
  {
    id: 47050,
    userId: 3,
    type: 'Popular',
    position: 7,
    date: '2020-03-02T09:24:00',
    text: 'Уважаемые, не ждите трек на 5 раунд баттла, хочу потратить время на другое\n\nОт баттла я уже получил главное - выход из ступора)\nСпасибо всем, кто имел к этому отношение, надеюсь, и я вам чем-то помог.\nДвигаюсь дальше',
    repostCount: 456,
    commentsCount: 890,
    viewsCount: 61290,
    reactions: [
      { reactionId: 2, count: 720 },
      { reactionId: 5, count: 134 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/users/paul.png'),
        authorName: 'Paul Anderson',
        date: '2020-03-03T23:18:00',
        text: 'Чекаємо 8 годинний розбір від Плюшевого',
        likesCount: 194,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/users/patso.png'),
        authorName: 'Ostap Patso',
        date: '2020-03-02T13:30:00',
        text: 'Окс, ти виконав своє завдання на 5 з плюсом!',
        likesCount: 402,
      },
      {
        authorName: 'Orest Banan',
        date: '2020-03-03T18:42:00',
        text: 'Ебать, какой же ты конченый. Просто фантастика, насколько ты хуесос.\nОбидно конечно, но главное чтобы тебе от этого было лучше',
        likesCount: 291,
      },
    ],
  },
  // D U R O V - 46050
  {
    id: 46050,
    userId: 6,
    type: 'Popular',
    position: 1,
    category: 'Tehnology',
    date: '2020-03-12T13:30:00',
    title: 'Today we are announcing the closure of the TON',
    text: 'Today is a sad day for us here at Telegram. We are announcing the discontinuation of our blockchain project. Below is a summary of what it was and why we had to abandon it.\n\nhttps://telegra.ph/What-Was-TON-And-Why-It-Is-Over-05-12',
    repostCount: 12723,
    commentsCount: 5671,
    viewsCount: 234901,
    reactions: [
      { reactionId: 1, count: 24 },
      { reactionId: 2, count: 8712 },
      { reactionId: 3, count: 722 },
      { reactionId: 4, count: 32981 },
      { reactionId: 5, count: 1290 },
    ],
    comments: [
      {
        // eslint-disable-next-line
            authorPhoto: require('../img/comments/user19.png'),
        authorName: 'Alexandra Reed',
        date: '2020-03-12T14:32:00',
        text: 'I think this is just a wrench in the works from the U.S. I’m sure there’s an interesting continuation of this story ahead because Pavel isn’t the kind of person who gives up easily.',
        likesCount: 2341,
      },
      {
        // eslint-disable-next-line
            authorPhoto: require('../img/comments/user6.png'),
        authorName: 'Jonathan Wells',
        date: '2020-03-13T16:32:00',
        text: 'Any cryptocurrency created by a company and not approved by government institutions is bound to meet a similar fate. Crypto can only survive if it’s 100% decentralized and beyond control.',
        likesCount: 1410,
      },
      {
        // eslint-disable-next-line
            authorPhoto: require('../img/comments/user20.png'),
        authorName: 'David Collins',
        date: '2020-03-13T05:01:00',
        text: 'What about the call for U.S. residents to throw paper planes out of their windows in support of freedom and democracy? :(',
        likesCount: 732,
      },
    ],
  },
  {
    id: 46049,
    userId: 6,
    type: 'Recommended',
    position: 7,
    date: '2020-03-11T16:50:00',
    title: 'When the pandemic is over, the world will not return to normal',
    text: 'The current pandemic is a threat to our entire species. When it ends, the world will not return to normal. We may witness a civilizational shift that will ripple through generations. It is up to all of us to ensure that the new world about to be born is a better place than the one were leaving behind.\n\nThis is a chance for people to use their time in isolation to create a better version of themselves – and a chance for technology to prove its worth for humanity. I believe we at Telegram should do all we can not only to help contain the pandemic and combat the spread of unverified information – but also to find new ways of moving forward.\n\nFor this reason, in addition to providing informational support, we’ll try to contribute to tackling the problem of education under lockdown. We also have several other anti-covid19 projects in the works at Telegram.\n\nI will announce more details in the next few days on the Telegram Blog.\n\nStay tuned. And stay safe.',
    repostCount: 56789,
    viewsCount: 234901,
    reactions: [
      { reactionId: 1, count: 2459 },
      { reactionId: 2, count: 15690 },
      { reactionId: 4, count: 45678 },
      { reactionId: 5, count: 4910 },
    ],
  },
  // R E D D I T  U P V O T E - 45050
  {
    id: 45050,
    userId: 5,
    type: 'Recommended',
    position: 2,
    date: '2020-03-13T09:12:00',
    category: 'Health',
    title: '🦠 What long-term consequences of 2020 are we overlooking?',
    text: 'What do you think are the less obvious consequences of COVID-19? What might happen in a few years that we don’t suspect now?',
    repostCount: 1424,
    commentsCount: 935,
    viewsCount: 68909,
    reactions: [
      { reactionId: 4, count: 893 },
      { reactionId: 2, count: 320 },
      { reactionId: 5, count: 108 },
      { reactionId: 1, count: 3 },
      { reactionId: 3, count: 31 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user3.png'),
        authorName: 'Ethan Caldwell',
        date: '2020-03-13T09:43:00',
        text: 'People spend a lot of time alone, and because of this, they develop mental disorders.',
        likesCount: 430,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user2.png'),
        authorName: 'Sophie Bennett',
        date: '2020-03-13T10:08:00',
        text: 'When we all have to return to the office, it will break our dogs’ hearts, and they will suffer from separation anxiety 😰',
        likesCount: 304,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user4.png'),
        authorName: 'Daniel Peterson',
        date: '2020-03-13T10:15:00',
        text: 'People graduating in some fields today do not gain enough experience to start their careers properly. I already know a couple of young teachers who, throughout their entire education, never had the chance to conduct a lesson in a regular classroom without COVID restrictions. Another acquaintance of mine is about to become a therapist, yet he has never seen a patient face-to-face—during his studies, all his sessions were conducted online. And these are just the ones I know personally.',
        likesCount: 221,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user6.png'),
        authorName: 'James Holloway',
        date: '2020-03-13T11:58:00',
        text: 'Millions of small businesses have been left out of the game. This benefits no one—except, perhaps, giant corporations like Walmart, Target, and the like.',
        likesCount: 846,
      },
    ],
  },
  {
    id: 45048,
    userId: 5,
    date: '2020-03-11T20:18:00',
    category: 'Health',
    title: 'Girls, tell us about your boyfriends',
    text: 'Describe the strange or unexpected things you noticed about your boyfriend’s habits after moving in together',
    repostCount: 798,
    commentsCount: 2341,
    viewsCount: 111909,
    reactions: [
      { reactionId: 1, count: 135 },
      { reactionId: 2, count: 477 },
      { reactionId: 3, count: 1240 },
      { reactionId: 4, count: 56 },
      { reactionId: 5, count: 2 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user7.png'),
        authorName: 'Anna Mitchell',
        date: '2020-03-11T21:32:00',
        text: 'They have all sorts of “rules.” For example, a T-shirt isn’t considered dirty unless it smells, gets wet, or has visible stains. Before it goes into the laundry, they might wear it multiple times.',
        likesCount: 2458,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user8.png'),
        authorName: 'Emily Carter',
        date: '2020-03-12T06:24:00',
        text: 'Turns out he can sit at his computer from morning till night, playing video games without getting distracted—not for food, not for the bathroom… he doesn’t even need light.',
        likesCount: 1540,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user9.png'),
        authorName: 'Jessica Miller',
        date: '2020-03-12T09:12:00',
        text: 'Zoning out on the toilet is sacred for a man. It’s the perfect opportunity to catch up on missed news and scroll through wepats 😅\n\nThe only problem? It gets a bit inconvenient when you need the bathroom, and he’s already been in there for half an hour 🤷‍♀️',
        likesCount: 3540,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user19.png'),
        authorName: 'Olivia Brooks',
        date: '2020-03-12T23:01:00',
        text: 'They don’t eat three times a day. Why have three meals when you can just have one or two gigantic ones?',
        likesCount: 1120,
      },
    ],
  },
  {
    id: 45049,
    userId: 5,
    type: 'Recommended',
    position: 5,
    date: '2020-03-12T21:03:00',
    category: 'Health',
    title: 'What COVID-related things will be completely forgotten?',
    text: 'What do you think—what things we are experiencing now in the COVID-19 era will be successfully forgotten after it ends?',
    repostCount: 3451,
    commentsCount: 5612,
    viewsCount: 111909,
    reactions: [
      { reactionId: 1, count: 135 },
      { reactionId: 2, count: 477 },
      { reactionId: 3, count: 1240 },
      { reactionId: 4, count: 7120 },
      { reactionId: 5, count: 4312 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user23.png'),
        authorName: 'Michael Turner',
        date: '2020-03-12T21:32:00',
        text: 'Empty streets and highways for a whole year. I think once all the pandemic hype is over, our commutes to work will take much longer.',
        likesCount: 9200,
      },
      {
        authorName: 'Daniel Foster',
        date: '2020-03-13T07:12:00',
        text: '🤝 I think we’ll stop greeting each other with elbow bumps and go back to regular handshakes.',
        likesCount: 5700,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user9.png'),
        authorName: 'Sophia Bennett',
        date: '2020-03-11T23:17:00',
        text: 'The habit of wiping everything down with antibacterial wipes will disappear.',
        likesCount: 3540,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user27.png'),
        authorName: 'Emma Richardson',
        date: '2020-03-13T11:23:00',
        text: 'The constant Zoom and Google Meet calls with friends and family will disappear. I freaking hate this 🤬🤬🤬',
        likesCount: 2341,
      },
    ],
  },
  // T J O U R N A L - 44050
  {
    id: 44050,
    userId: 8,
    type: 'Popular',
    position: 4,
    category: 'Music',
    date: '2020-03-14T12:13:00',
    title: 'Official: Eurovision 2020 canceled due to coronavirus',
    text: '😭 The organizers decided to postpone the contest to 2021. All artists who qualified this year will be able to participate.',
    repostCount: 91,
    commentsCount: 2,
    viewsCount: 41091,
    reactions: [
      { reactionId: 2, count: 47 },
      { reactionId: 4, count: 109 },
      { reactionId: 5, count: 11 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user5.png'),
        authorName: 'Emma Richardson',
        date: '2020-03-14T14:08:00',
        text: 'This is so disappointing! I was really looking forward to watching my favorite artists perform live. But safety comes first',
        likesCount: 12,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user9.png'),
        authorName: 'Emma Richardson',
        date: '2020-03-14T12:19:00',
        text: 'I hope they at least let the same songs compete next year. It would be unfair to make the artists start over!',
        likesCount: 25,
      },
    ],
  },
  {
    id: 44049,
    userId: 8,
    type: 'Recommended',
    position: 6,
    date: '2020-03-11T09:27:00',
    title: 'The meme about dancing coffin bearers from Africa is the top meme of Spring 2020',
    text: 'After gaining popularity back in March, clips about undertakers quickly began to rival the number of jokes with the coronavirus and general quarantine.',
    // eslint-disable-next-line
    media: require('../img/media/tj1.png'),
    repostCount: 19,
    commentsCount: 1,
    viewsCount: 56590,
    reactions: [
      { reactionId: 1, count: 9 },
      { reactionId: 2, count: 28 },
      { reactionId: 3, count: 96 },
      { reactionId: 5, count: 1 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user4.png'),
        authorName: 'Mike Billerd',
        date: '2020-03-12T11:32:00',
        text: 'This meme has already started to annoy me 🤬',
        likesCount: 25,
      },
    ],
  },
  {
    id: 44048,
    userId: 8,
    type: 'Recommended',
    position: 13,
    date: '2020-03-10T08:11:00',
    title: '🧻 The Great Toilet Paper Rush: Fear Over Logic',
    text: 'Due to the coronavirus epidemic, people around the world are panic-buying toilet paper, despite the lack of any real necessity. After all, it offers no protection against Covid-19.\n\nWhile social media jokes that toilet rolls might soon replace currency, psychologists blame human fear for the buying frenzy. Meanwhile, the media predicts that the shortage will soon end, and shoppers will shift their focus to other essentials like bottled water.',
    repostCount: 431,
    commentsCount: 134,
    viewsCount: 71590,
    reactions: [
      { reactionId: 1, count: 9 },
      { reactionId: 2, count: 28 },
      { reactionId: 3, count: 224 },
      { reactionId: 4, count: 16 },
      { reactionId: 5, count: 71 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user40.png'),
        authorName: 'Michael Harris',
        date: '2020-03-10T08:45:00',
        text: 'At this point, toilet paper has a better exchange rate than my country’s currency.',
        likesCount: 201,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user41.png'),
        authorName: 'Sarah Collins',
        date: '2020-03-10T11:12:00',
        text: 'Imagine explaining to future generations that the first reaction to a global pandemic was hoarding toilet paper 😅',
        likesCount: 94,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user23.png'),
        authorName: 'Jake Thompson',
        date: '2020-03-11T03:43:00',
        text: 'So, are we fighting the virus or preparing for a medieval barter system?',
        likesCount: 42,
      },
    ],
  },
  // П О П Е Р Е Ч Н И Й - 43050
  {
    id: 43050,
    userId: 10,
    type: 'Recommended',
    position: 8,
    date: '2020-03-11T07:02:00',
    text: '2019: сидишь дома,\n2020: сидишь дома чуть более тревожно',
    repostCount: 3451,
    commentsCount: 421,
    viewsCount: 56590,
    reactions: [
      { reactionId: 1, count: 2 },
      { reactionId: 2, count: 28 },
      { reactionId: 3, count: 2310 },
      { reactionId: 4, count: 912 },
      { reactionId: 5, count: 1430 },
    ],
    comments: [
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user20.png'),
        authorName: 'Benjamin Harris',
        date: '2020-03-12T11:46:00',
        text: 'I can’t wait for COVID to be over. This is the worst thing that could have happened to humanity. I’m sure 2021 and 2022 will be the best years ever.',
        likesCount: 232,
      },
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user18.png'),
        authorName: 'Milos Novak',
        date: '2020-03-11T09:42:00',
        text: 'Когда новый стендап, рыжий?',
        likesCount: 98,
      },
    ],
  },
  {
    id: 43049,
    userId: 10,
    date: '2020-02-24T10:09:00',
    type: 'Popular',
    position: 3,
    category: 'Podcast',
    title: '🎙Без души: Эльдар Джарахов',
    text: 'ТикТок, "Команда Э", диабет и стволовые клетки, конец отношений\n\nhttps://www.youtube.com/watch?v=s3dhh6fcso0',
    // eslint-disable-next-line
    media: require('../img/media/poper1.png'),
    repostCount: 28912,
    commentsCount: 3981,
    viewsCount: 182091,
    reactions: [
      { reactionId: 1, count: 6712 },
      { reactionId: 2, count: 1090 },
      { reactionId: 3, count: 561 },
      { reactionId: 4, count: 12 },
      { reactionId: 5, count: 86 },
    ],
    comments: [
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user28.png'),
        authorName: 'Yaroslav Joaquin',
        date: '2020-03-01T10:46:00',
        text: 'Эд Ширан пытает маленького мальчика на протяжении двух часов.',
        likesCount: 4109,
      },
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user45.png'),
        authorName: 'Bogdan Novak',
        date: '2020-03-02T09:12:00',
        text: 'Если бы я не прочел названия видео, то я бы так и не разобрался, почему Милонов кричит на ребенка.....',
        likesCount: 1082,
      },
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user44.png'),
        authorName: 'Boris Kovalenko',
        date: '2020-03-02T18:23:00',
        text: 'Просыпаюсь в три часа ночи на вписке, иду попить водички\nПацаны на кухне:',
        likesCount: 2482,
      },
    ],
  },
  // D E V   42050
  {
    id: 42050,
    userId: 11,
    type: 'Recommended',
    position: 8,
    date: '2020-03-10T18:32:00',
    text: 'We all google for solutions, a more experienced developer just knows the right keywords.',
    // eslint-disable-next-line
    media: require('../img/media/dev1.png'),
    repostCount: 88,
    commentsCount: 47,
    viewsCount: 19590,
    reactions: [
      { reactionId: 1, count: 28 },
      { reactionId: 3, count: 504 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user11.png'),
        authorName: 'Jose Joaquin',
        date: '2020-03-11T14:58:00',
        text: 'I dont think so, my buddy its a real developer and he never ask stake overflow',
        likesCount: 232,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user10.png'),
        authorName: 'Ahmad Fathy',
        date: '2020-03-10T22:21:00',
        text: 'Soon, StackOverflow will not be needed, all problems will be solved by AI. Mark my words! 😁',
        likesCount: 98,
      },
    ],
  },
  // M O V I E S - 41050
  {
    id: 41050,
    userId: 12,
    date: '2020-03-13T19:04:00',
    title: '🦇 Filming of Matt Reeves’ “The Batman” officially halted for 2 weeks',
    text: 'The production was taking place in London. During this two-week pause, it will move to Liverpool, where the team will resume filming.',
    repostCount: 14134,
    commentsCount: 6109,
    viewsCount: 231988,
    reactions: [
      { reactionId: 2, count: 1712 },
      { reactionId: 4, count: 4823 },
      { reactionId: 5, count: 843 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user28.png'),
        authorName: 'Daniel Evans',
        date: '2020-03-13T21:01:00',
        text: 'Health comes first, but I really hope this doesn’t affect the final quality of the film. Fingers crossed!',
        likesCount: 4561,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user32.png'),
        authorName: 'James Walker',
        date: '2020-03-14T05:16:00',
        text: 'Another delay? At this rate, Batman will be fighting crime in 2025! Hope everyone stays safe',
        likesCount: 2091,
      },
    ],
  },
  {
    id: 41049,
    userId: 12,
    type: 'Recommended',
    position: 9,
    date: '2020-03-08T12:02:00',
    title: '🍿 New teaser for Stranger Things',
    text: 'The first teaser for the new season of A Very Strange Thing has appeared online.\n\nWith the subtitle - “From Russia with Love”: https://youtu.be/oB2GYwbIAlM',
    repostCount: 28912,
    commentsCount: 75123,
    viewsCount: 459590,
    reactions: [
      { reactionId: 1, count: 12450 },
      { reactionId: 2, count: 4712 },
      { reactionId: 3, count: 113 },
      { reactionId: 4, count: 823 },
      { reactionId: 5, count: 43 },
    ],
    comments: [
      {
        authorName: 'Mike Joaquin',
        date: '2020-03-10T21:01:00',
        text: 'David Harbour: \'I can\'t film season four guys, I\'m in Russia filming with Marvel.\' Netflix: \'I can work with that.\'',
        likesCount: 4561,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user26.png'),
        authorName: 'Jack Dorsi',
        date: '2020-03-08T23:41:00',
        text: 'Just finished the whole series for the 3rd time. God I love Stranger Things. Although season 3 ending is sooooo sad. And whoever wrote the never-ending story scene with dustin deserves an oscar.',
        likesCount: 2091,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user39.png'),
        authorName: 'Mike Witman',
        date: '2020-03-08T23:41:00',
        text: 'Hopper’s hair was the only part of him that died',
        likesCount: 3511,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user38.png'),
        authorName: 'Jack Thompson',
        date: '2020-03-08T23:41:00',
        text: 'Sometimes I wonder whatever’s going on with Mike’s dad, like is he still clueless to his surroundings? 😂',
        likesCount: 645,
      },
    ],
  },
  // B A C K  T O  80  -  40050
  {
    id: 40050,
    userId: 9,
    date: '2020-03-08T12:09:00',
    title: 'Logos in the style of the 80\'s',
    text: 'Logos of Reddit, Google, Spotify and Burger King in the style of the 80s\n\nhttps://www.fael.pt/work/lettering-series-lxxix',
    // eslint-disable-next-line
    media: require('../img/media/google.png'),
    repostCount: 208,
    commentsCount: 45,
    viewsCount: 13430,
    reactions: [
      { reactionId: 1, count: 320 },
      { reactionId: 2, count: 83 },
      { reactionId: 4, count: 8 },
    ],
    comments: [
      {
        authorName: 'Lily Anderson',
        date: '2020-03-10T12:01:00',
        text: 'I miss those times—everything looked so aesthetic back then 😭😭',
        likesCount: 46,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user29.png'),
        authorName: 'Daniel M.',
        date: '2020-03-08T23:08:00',
        text: 'Wooow 😍',
        likesCount: 21,
      },
    ],
  },
  // M E M E S - 39050
  {
    id: 39050,
    userId: 14,
    type: 'Recommended',
    position: 12,
    date: '2020-03-13T11:12:00',
    text: 'We live in a new reality',
    // eslint-disable-next-line
    media: require('../img/media/mem1.png'),
    repostCount: 3421,
    commentsCount: 319,
    viewsCount: 134567,
    reactions: [
      { reactionId: 1, count: 651 },
      { reactionId: 3, count: 3456 },
      { reactionId: 4, count: 45 },
      { reactionId: 5, count: 4 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user32.png'),
        authorName: 'Lily Anderson',
        date: '2020-03-13T12:08:00',
        text: '🤣🤣🤣',
        likesCount: 40,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user41.png'),
        authorName: 'Emily Robertson',
        date: '2020-03-14T15:01:00',
        text: 'This feels more like remote learning and the interaction between my PE teacher and my dad',
        likesCount: 309,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user34.png'),
        authorName: 'Maria',
        date: '2020-03-13T12:08:00',
        text: 'How good it is that I live alone',
        likesCount: 132,
      },
    ],
  },
  {
    id: 39049,
    userId: 14,
    type: 'Popular',
    position: 10,
    category: 'Humor',
    date: '2020-03-12T10:58:00',
    title: 'The most popular meme of March',
    text: 'Due to global lockdowns, Venice’s waters have become so clear that dolphins have returned. Nature is healing. The real problem for Earth isn’t the virus—it’s us.\n\nActually, no. This is just a viral social media hoax. But it has sparked a meme about various things “returning to their natural habitat”—from dinosaurs and the 90s to the USSR and a long-lost father who left the family years ago.',
    repostCount: 1092,
    commentsCount: 609,
    viewsCount: 364812,
    reactions: [
      { reactionId: 1, count: 891 },
      { reactionId: 2, count: 190 },
      { reactionId: 3, count: 2109 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user35.png'),
        authorName: 'Ethan Brooks',
        date: '2020-03-12T11:03:00',
        text: 'Nature has healed so much that Internet Explorer is now the fastest browser.',
        likesCount: 341,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user31.png'),
        authorName: 'Sophia Miller',
        date: '2020-03-14T15:01:00',
        text: 'The air is so clean that Tamagotchis have started coming back to life, demanding food after 20 years.',
        likesCount: 481,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user38.png'),
        authorName: 'Jack Reynolds',
        date: '2020-03-13T12:08:00',
        text: 'Nature has recovered to the point where Nokia 3310s are once again the dominant species',
        likesCount: 112,
      },
    ],
  },
  // B I L L  G A T E S - 38050
  {
    id: 38050,
    userId: 15,
    type: 'Popular',
    position: 6,
    date: '2020-03-13T11:08:00',
    category: 'Health',
    title: 'How to respond to COVID-19',
    text: 'COVID-19 could be a once-in-a-century pandemic, but the good news is that there are steps we can take now to slow its impact and help us respond more effectively when the next epidemic arrives.\n\nhttps://www.gatesnotes.com/Health/How-to-respond-to-COVID-19',
    repostCount: 5781,
    commentsCount: 2662,
    viewsCount: 289123,
    reactions: [
      { reactionId: 1, count: 8912 },
      { reactionId: 4, count: 1230 },
      { reactionId: 2, count: 412 },
    ],
    comments: [
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user32.png'),
        authorName: 'Lasga Seck',
        date: '2020-03-13T12:08:00',
        text: 'Youre saving lives by investing morney to respond more effectively to this pandemic outbreak.Thank you so much .',
        likesCount: 184,
      },
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user36.png'),
        authorName: 'Shafeeq Mohammed',
        date: '2020-03-13T12:08:00',
        text: 'I think it was planned and executed to control population, it may come under control by summer',
        likesCount: 67,
      },
    ],
  },
  // C H B D - 37050
  {
    id: 37050,
    userId: 18,
    date: '2020-03-12T09:12:00',
    title: 'Yuriy Dud and Lolita in the new episode of CHBD',
    text: 'A new episode of the comedy show “CHBD?”. The guests were Yuri Dud and Lolita Milyavskaya.\n\nhttps://www.youtube.com/@chbdchbdchbd',
    // eslint-disable-next-line
    media: require('../img/media/chbd1.png'),
    repostCount: 19781,
    commentsCount: 10921,
    viewsCount: 1882132,
    reactions: [
      { reactionId: 1, count: 6123 },
      { reactionId: 2, count: 1321 },
      { reactionId: 3, count: 22921 },
      { reactionId: 4, count: 91 },
      { reactionId: 5, count: 412 },
    ],
    comments: [
      {
        // eslint-disable-next-line
            authorPhoto: require('../img/comments/user35.png'),
        authorName: 'Alex Mercer',
        date: '2020-03-13T13:18:00',
        text: 'My God, what a great ad. Hooow well-made.\nAnd how much I miss the reptiloid 😢',
        likesCount: 3241,
      },
    ],
  },
  // P Y R O K I N E S I S - 36050
  {
    id: 36050,
    userId: 16,
    type: 'Recommended',
    position: 10,
    date: '2020-03-10T18:00:00',
    title: 'Питер, чай, не Франция',
    text: 'Release date: December 4, 2020 🔥🔥🔥\nAvailable for pre-order:\nhttps://open.spotify.com/album/4QTQIR6xbGKXaR2AqDsrX1',
    // eslint-disable-next-line
    media: require('../img/media/pyrokinesis1.png'),
    repostCount: 1321,
    commentsCount: 2310,
    viewsCount: 230671,
    reactions: [
      { reactionId: 1, count: 7123 },
      { reactionId: 2, count: 2401 },
      { reactionId: 4, count: 91 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user36.png'),
        authorName: 'Egor Letov',
        date: '2020-03-10T19:24:00',
        text: 'Let’s raise a toast to the Lord’s new rebirth.',
        likesCount: 1092,
      },
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user34.png'),
        authorName: 'Eliza Milford',
        date: '2020-03-11T10:16:00',
        text: 'Haven’t listened yet, but I’m sure it’s a mind-blower.\n\nUpd: Listened to almost everything—this exceeded all my expectations.\nUpd: Finished it all—my mind is blown to pieces across the room. I liked this even more than Sweet Emptiness.',
        likesCount: 816,
      },
    ],
  },
  // Z E L E N S K Y - 35050
  {
    id: 35050,
    userId: 17,
    type: 'Recommended',
    position: 11,
    date: '2020-03-13T02:24:00',
    title: 'Звернення президента щодо протидії коронавірусу ',
    text: 'Досвід Китаю засвідчує, що непопулярні та жорсткі рішення долають вірус і рятують життя. Досвід інших країн показує, що м’якотілість та ліберальність є союзниками коронавірусу. Тому ми будемо діяти жорстко, невідкладно, можливо, непопулярно, але заради одного – життя та здоров’я українців.\n\nhttps://www.president.gov.ua/news/zvernennya-glavi-derzhavi-shodo-protidiyi-koronavirusu-60181',
    repostCount: 1321,
    commentsCount: 0,
    viewsCount: 274190,
    reactions: [
      { reactionId: 2, count: 2401 },
      { reactionId: 4, count: 4123 },
      { reactionId: 5, count: 1210 },
    ],
  },
  // D E A T H B U R G E R - 34050
  {
    id: 34050,
    userId: 19,
    type: 'Recommended',
    position: 4,
    date: '2020-03-13T14:59:00',
    title: 'The Future is Now',
    text: 'Day 5. In The Court of The Laundry King. One of my favourite pages of Nightfall, and my work in general',
    // eslint-disable-next-line
    media: require('../img/media/deathburger1.png'),
    repostCount: 1098,
    commentsCount: 245,
    viewsCount: 93451,
    reactions: [
      { reactionId: 1, count: 4512 },
      { reactionId: 2, count: 1092 },
      { reactionId: 3, count: 45 },
    ],
    comments: [
      {
        // eslint-disable-next-line
        authorPhoto: require('../img/comments/user44.png'),
        authorName: 'John Milford',
        date: '2020-03-13T19:24:00',
        text: 'Fantastic artwork and details! Phenomenal use of colour and atmosphere!',
        likesCount: 103,
      },
      {
        // eslint-disable-next-line
          authorPhoto: require('../img/comments/user38.png'),
        authorName: 'Mike Robertson',
        date: '2020-03-13T10:16:00',
        text: 'I said “so cool” out loud to myself when I saw it',
        likesCount: 816,
      },
    ],
  },
]);

export default posts;
