<template>
    <div class="input">
      <label class="input_label" v-if="label" :for="inputId">
        {{ label }}
      <div class="input_wrapper" :class="{ 'error': isError }">
        <input
          :type="inputType"
          :id="inputId"
          :placeholder="placeholder"
          :value="modelValue"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <button v-if="type === 'password'" class="input_icon"
        @click="togglePasswordVisibility">
          <img :src="isPasswordVisible ? eyeSlashIcon : eyeIcon" alt="toggle password" />
        </button>
      </div>
    </label>
    </div>
  </template>

<script>
import { ref, computed } from 'vue';

import likeIcon from '../../img/icons/form/eye.svg'; // Шлях залежить від структури
import repostIcon from '../../img/icons/form/eye-off.svg';

export default {
  name: 'InputComponent',
  props: {
    label: String,
    placeholder: String,
    isError: Boolean,
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const isPasswordVisible = ref(false);

    const eyeIcon = ref(likeIcon);
    const eyeSlashIcon = ref(repostIcon);

    const inputId = computed(() => `input-${Math.random().toString(36).substring(2, 15)}`);

    const togglePasswordVisibility = () => {
      isPasswordVisible.value = !isPasswordVisible.value;
    };

    const inputType = computed(() => (props.type === 'password' && isPasswordVisible.value ? 'text' : props.type));

    return {
      eyeIcon,
      eyeSlashIcon,
      isPasswordVisible,
      inputId,
      togglePasswordVisibility,
      inputType,
    };
  },
};
</script>

<style scoped lang="scss">
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2rem;
  &_label {
    @include text-14-medium;
  }
  &_wrapper {
    display: flex;
    align-items: center;
    border: .1rem solid $border-gray;
    margin-top: .4rem;
    border-radius: .8rem;
    @include text-14-regular;
    height: 4rem;
    padding: 0 1.2rem;
    transition: .5s;
    &.error {
      border: .1rem solid $border-red;
    }
    ::placeholder {
      color: $text-placeholder;
      @include text-14-regular;
    }
    input {
      width: 100%;
      border: none;
    }
    &:focus-within {
      border-color: $border-blue;
      transition: .5s;
    }
  }
  &_icon {
    height: 4rem;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 2rem;
    }
  }
}
</style>
