<template>
  <div class="section">
    <div class="section_header">
      <p>What’s happening</p>
      <router-link to="/popular">Show more</router-link>
    </div>
    <ul class="section_list">
      <li class="news" v-for="post in recommendedPosts" :key="post.id">
        <router-link :to="`${post.user.username}/post/${post.id}`">
          <div class="news_info">
            <div class="news_info-photo">
              <img :src="post.user.photo" :alt="post.user.name">
            </div>
            <div class="news_info-author">
              <p>{{ post.user.name }}</p>
              <img v-if="post.user.verified" src="../img/icons/post/verified.svg" alt="verified">
              <span>{{ post.category }}</span>
            </div>
          </div>
          <div class="news_content">
            {{ post.title }}
          </div>
          <div class="news_views">
            {{ formatNumber(post.viewsCount) }} views
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { ref, onMounted, computed } from 'vue';
import users from '@/functions';
import formatNumber from '../functions/format';

export default {
  name: 'HotNews',
  setup() {
    const recommendedPosts = ref([]);

    const filteredPosts = computed(() => {
      if (!users.value || !Array.isArray(users.value)) {
        return []; // Повертаємо пустий масив, якщо users.value не існує або не є масивом
      }

      const allPosts = users.value.flatMap((user) => {
        if (!user.posts || !Array.isArray(user.posts)) {
          return []; // Повертаємо пустий масив, якщо user.posts не існує або не є масивом
        }
        return user.posts.map((post) => ({
          ...post,
          user: {
            id: user.id,
            name: user.name,
            photo: user.photo,
            username: user.username,
            verified: user.verified,
          },
        }));
      });
      return allPosts.filter((post) => post.type === 'Popular' && post.title);
    });

    const randomizedPosts = computed(() => {
      const posts = [...filteredPosts.value];
      // eslint-disable-next-line
      for (let i = posts.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [posts[i], posts[j]] = [posts[j], posts[i]];
      }
      return posts.slice(0, 3);
    });

    onMounted(() => {
      recommendedPosts.value = randomizedPosts.value;
    });

    return {
      recommendedPosts, formatNumber,
    };
  },
};
</script>
<style lang="scss">
.news {
  width: 100%;
  margin-bottom: 1.6rem;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: .5s;
    &:hover {
      opacity: .6;
      transition: .5s;
    }
  }
  &_type {
    color: $text-gray;
    @include text-13-regular;
    margin-bottom: .4rem;
  }
  &_info {
    display: flex;
    align-items: center;
    margin-bottom: .4rem;
    &-photo {
      width: 2rem;
      height: 2rem;
      position: relative;
      border-radius: 100%;
      overflow: hidden;
      margin-right: .8rem;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-author {
      display: flex;
      align-items: center;
      @include text-14-medium;
      img {
        width: 1.6rem;
        margin-left: .4rem;
      }
      span {
        display: flex;
        align-items: center;
        margin-left: 0.6rem;
        position: relative;
        @include text-14-regular;
        color: $text-gray;
        &:before {
          content: "";
          margin-right: 0.6rem;
          display: block;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 100%;
          background: $text-gray;
        }
      }
    }
  }
  &_content {
    @include text-15-semibold;
    margin-bottom: .4rem;
  }
  &_views {
    color: $text-gray;
    @include text-13-regular;
  }
}
</style>
