<template >
  <div class="footer">
    <ul class="footer_links">
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/features">Features</router-link>
      </li>
      <li>
        <router-link to="/help">Help Center</router-link>
      </li>
      <li>
        <router-link to="/business">For business</router-link>
      </li>
      <!-- <li>
        <router-link to="/">Terms</router-link>
      </li>
      <li>
        <router-link to="/">Legal information</router-link>
      </li> -->
    </ul>
    <div class="footer_info">
      wepats © 2025. All rights reserved.
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterLinks',
};
</script>
<style lang="scss">
.footer {
  display: flex;
  flex-direction: column;
  padding-top: .8rem;
  &_links {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 1.2rem;
    li {
      &:last-child {
        margin-right: 0;
      }
      a {
        @include text-13-regular;
        color: $text-gray;
      }
    }
  }
  &_info {
    @include text-13-regular;
  }
}
</style>
