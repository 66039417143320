<template>
  <Popup v-model:show="showPopup">
    <template #content>
      <div class="popup">
        <div class="popup_wrapper">
          <div class="popup_gradient"></div>
          <div class="popup_heading">
            <div class="popup_heading-icon">
              <img src="../../img/icons/popup/face.svg" alt="icon">
            </div>
            <h3 class="popup_heading-title">Registration is suspended</h3>
            <p class="popup_heading-desc">Since March 14, 2020, we have suspended new user
              registrations indefinitely. However, if you created an account before that
              date, you can still:</p>
          </div>
          <ul class="features">
            <li class="feature_item">
              <div class="feature_item-heading">
                <img src="../../img/icons/popup/posts.svg" alt="icon">
                Interact with posts
              </div>
              <p class="feature_item-text">Edit, delete your own posts, and view
                publications from other users.</p>
            </li>
            <li class="feature_item">
              <div class="feature_item-heading">
                <img src="../../img/icons/popup/messages.svg" alt="icon">
                Access private messages
              </div>
              <p class="feature_item-text">View your conversations, send, and receive
                text messages*.</p>
            </li>
            <li class="feature_item">
              <div class="feature_item-heading">
                <img src="../../img/icons/popup/edit.svg" alt="icon">
                Edit your profile
              </div>
              <p class="feature_item-text">Hide your account, change your profile picture,
                bio, and other profile details.</p>
            </li>
            <li class="feature_item">
              <div class="feature_item-heading">
                <img src="../../img/icons/popup/comment.svg" alt="icon">
                Comment and react
              </div>
              <p class="feature_item-text">Leave comments and react to posts.</p>
            </li>
          </ul>
          <div class="popup_form">
            <button class="btn black" @click="handleGoToLoginButtonClick">I have an account</button>
            <div class="popup_form-text">
              If you still have questions, visit the
              <router-link to="/">help center</router-link>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { useShowPopupLogic, useShowPropDeclaration } from './utilities/showPropLogic';

import Popup from './Popup.vue';

export default {
  name: 'PopupJoin',
  components: {
    Popup,
  },
  useShowPropDeclaration,
  emits: ['update:show', 'openLoginPopup'],
  setup(props, { emit }) {
    const showPopup = useShowPopupLogic(props, emit);

    const handleGoToLoginButtonClick = () => {
      showPopup.value = false; // Закриваємо поточний попап
      emit('openLoginPopup'); // Повідомляємо батьківський компонент про необхідність відкрити попап логіну
    };
    return {
      showPopup, handleGoToLoginButtonClick,
    };
  },
};

</script>

<style scoped lang="scss">
.popup {
  &_gradient {
    background: linear-gradient(180deg, rgba(1, 119, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%);
  }
}
.features {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
}
.feature_item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
  &-heading {
    display: flex;
    align-items: center;
    @include text-15-semibold;
    color: $text-black;
    img {
      width: 2rem;
      margin-right: 1.2rem;
    }
  }
  &-text {
    color: $text-gray;
    @include text-14-regular;
    padding-left: 3.2rem;
    span {
      @include text-14-medium;
    }
  }
}
</style>
