import { ref } from 'vue';
import posts from './constants/posts';

const users = ref([
  {
    id: 1,
    name: 'Ostap Patso',
    verified: true,
    username: 'patso',
    // eslint-disable-next-line
    photo: require('./img/users/patso.png'),
    // eslint-disable-next-line
    background: require('./img/bg/patso.png'),
    birthday: 'October 5',
    desc: 'Founder and CEO of wepats (2016-2020)\nCo-founder easyflat (2022-2023)',
    about: 'Founder and CEO of wepats',
    followers: '11,7m',
    community: false,
    recommended: true,
    category: ['Entrepreneur'],
    social: [
      {
        website: 'wepats.com',
      },
      {
        instagram: 'patso_o',
      },
      {
        twitter: 'patso_o',
      },
    ],
    posts: [],
  },
  {
    id: 2,
    name: 'Kraków City',
    verified: false,
    username: 'krakow',
    // eslint-disable-next-line
    photo: require('./img/users/krakow.png'),
    // eslint-disable-next-line
    background: require('./img/bg/krakow.png'),
    desc: 'Oficjalny profil Krakowa / Official profile of Krakow',
    about: 'Rapper',
    followers: '103,8k',
    community: true,
    recommended: false,
    category: ['News & Politics'],
    social: [
      {
        website: 'krakow.pl',
      },
      {
        instagram: 'krakow_pl',
      },
      {
        twitter: 'krakow_pl',
      },
    ],
    posts: [],
  },
  {
    id: 3,
    name: 'Oxxxymiron',
    verified: false,
    username: 'norimyxxxo',
    // eslint-disable-next-line
    photo: require('./img/users/oxxxy.png'),
    // eslint-disable-next-line
    background: require('./img/bg/oxxxy.png'),
    birthday: 'January 31',
    desc: 'Rapping in Russian since forever.',
    about: 'Rapper',
    followers: '923,7k',
    community: false,
    recommended: true,
    category: ['Musician'],
    social: [
      {
        website: 'oxxymiron.com/',
      },
      {
        instagram: 'norimyxxxo',
      },
      {
        twitter: 'norimyxxxo',
      },
    ],
    posts: [],
  },
  {
    id: 4,
    name: 'Google',
    verified: false,
    username: 'google',
    // eslint-disable-next-line
    photo: require('./img/users/google.png'),
    // eslint-disable-next-line
    background: require('./img/bg/google.png'),
    desc: 'Google’s official page – your source for the latest updates, innovations, and insights. Discover new products and company news. Stay connected with Google’s world. 🚀',
    about: 'Google’s official page',
    followers: '678,9k',
    community: true,
    recommended: false,
    category: ['Technology', 'Internet Culture'],
    social: [
      {
        website: 'google.com',
      },
      {
        instagram: 'google',
      },
      {
        twitter: 'google',
      },
    ],
  },
  {
    id: 5,
    name: 'Upvote',
    verified: false,
    username: 'reddit_upvote',
    // eslint-disable-next-line
    photo: require('./img/users/reddit.png'),
    // eslint-disable-next-line
    background: require('./img/bg/upvote.png'),
    desc: 'Reddit upvote is the place to ask and answer thought-provoking questions.',
    about: 'Top stories from Reddit',
    followers: '1,4m',
    community: true,
    recommended: true,
    category: ['Humor'],
    social: [
      {
        website: 'reddit.com',
      },
      {
        instagram: 'reddit',
      },
    ],
    posts: [],
  },
  {
    id: 6,
    name: 'Paul Durov',
    verified: true,
    username: 'durov',
    // eslint-disable-next-line
    photo: require('./img/users/durov.png'),
    // eslint-disable-next-line
    background: require('./img/bg/durov.png'),
    birthday: 'October 10',
    desc: 'Founder, CEO at telegram (2013), founder, ex-CEO of vkontakte (2006), part-time troll.',
    about: 'Founder at telegram',
    followers: '6,8m',
    community: false,
    recommended: true,
    category: ['Entrepreneur'],
    social: [
      {
        website: 'telegram.org',
      },
      {
        twitter: 'durov',
      },
    ],
    posts: [],
  },
  {
    id: 7,
    name: 'Dribbble',
    verified: false,
    username: 'dribbble',
    // eslint-disable-next-line
    photo: require('./img/users/dribbble.png'),
    // eslint-disable-next-line
    background: require('./img/bg/dribbble.png'),
    desc: 'Dribbble is a global community for designers and creative professionals and an inspiration destination for tens of millions of people. This is Team Dribbble.',
    about: 'Design platform',
    followers: '80,6k',
    community: true,
    recommended: true,
    category: ['Design'],
    social: [
      {
        website: 'dribbble.com',
      },
      {
        instagram: 'dribbble',
      },
      {
        twitter: 'dribbble',
      },
    ],
    posts: [],
  },
  {
    id: 8,
    name: 'TJournal',
    verified: true,
    username: 'tjournal',
    // eslint-disable-next-line
    photo: require('./img/users/tj.png'),
    // eslint-disable-next-line
    background: require('./img/bg/tj.png'),
    desc: 'Internet News. Youre either on trend or youre off.',
    about: 'Internet News',
    followers: '63,7k',
    community: true,
    recommended: true,
    category: ['Design', 'News & Politics'],
    social: [
      {
        website: 'tjournal.ru',
      },
      {
        instagram: 'tjournal',
      },
    ],
    posts: [],
  },
  {
    id: 9,
    name: 'Back to 80s',
    verified: false,
    username: 'back80',
    // eslint-disable-next-line
    photo: require('./img/users/80.png'),
    // eslint-disable-next-line
    background: require('./img/bg/80.png'),
    desc: 'Aesthetics of the 80s. Photos, logos, fonts, and memories from people who lived in America in the 80s',
    about: 'Aesthetics of the 80s',
    followers: '46,7k',
    community: true,
    recommended: false,
    category: ['Internet Culture'],
    social: [
      {
        website: 'tjournal.ru',
      },
      {
        instagram: 'tjournal',
      },
    ],
    posts: [],
  },
  // ПОПЕРЕЧНИЙ
  {
    id: 10,
    name: 'Danila Poperechnij',
    verified: true,
    username: 'spoontamer',
    // eslint-disable-next-line
    photo: require('./img/users/spoontamer.png'),
    // eslint-disable-next-line
    background: require('./img/bg/spoontamer.png'),
    birthday: 'March 10',
    desc: '- Contact: spoontamer.team@gmail.com\n- My shop: veshdok.com',
    about: 'Internet News',
    followers: '510,3k',
    community: false,
    recommended: false,
    category: ['Blogger'],
    social: [
      {
        website: 'danilapoperechnyi.com',
      },
      {
        instagram: 'spoontamer',
      },
      {
        twitter: 'spoontamer',
      },
    ],
    posts: [],
  },
  // I T D E V
  {
    id: 11,
    name: '/dev/null',
    verified: false,
    username: 'devnull',
    // eslint-disable-next-line
    photo: require('./img/users/dev.png'),
    // eslint-disable-next-line
    background: require('./img/bg/dev.png'),
    desc: 'Humor about programming and all things related to development - take a break and dump your time into /dev/null.',
    about: 'IT humor',
    followers: '109,8k',
    community: true,
    recommended: false,
    category: ['Technology', 'Humor'],
    social: [
      {
        website: 'tprg.ru',
      },
    ],
    posts: [],
  },
  // N E T F L I X
  {
    id: 12,
    name: 'Netflix',
    verified: false,
    username: 'netflix',
    // eslint-disable-next-line
      photo: require('./img/users/netflix.png'),
    // eslint-disable-next-line
      background: require('./img/bg/netflix.png'),
    desc: 'Watch Gentefied! thank you in advance for agreeing.',
    about: 'Movies',
    followers: '311,8k',
    community: true,
    recommended: false,
    category: ['Movies'],
    social: [
      {
        website: 'netflix.com',
      },
      {
        instagram: 'netflix',
      },
      {
        twitter: 'netflix',
      },
    ],
    posts: [],
  },
  // N E W S
  {
    id: 13,
    name: 'News',
    verified: true,
    username: 'news_world',
    // eslint-disable-next-line
    photo: require('./img/users/news.png'),
    // eslint-disable-next-line
    background: require('./img/bg/news.png'),
    desc: 'The latest news from around the world.\nHourly updates on COVID-19',
    about: 'World News',
    followers: '3,8m',
    community: true,
    recommended: true,
    category: ['News & Politics'],
    social: [
      {
        website: 'netflix.com',
      },
      {
        instagram: 'netflix',
      },
      {
        twitter: 'netflix',
      },
    ],
    posts: [],
  },
  // M E M E S
  {
    id: 14,
    name: 'Memes',
    verified: false,
    username: 'memes',
    // eslint-disable-next-line
    photo: require('./img/users/memes.png'),
    // eslint-disable-next-line
    background: require('./img/bg/memes.png'),
    desc: 'Fresh memes daily – sharp humor, trending jokes, and a bit of madness.',
    about: 'Humor',
    followers: '1,4m',
    community: true,
    recommended: false,
    category: ['Humor', 'Internet Culture'],
    social: [
      {
        instagram: 'memes',
      },
      {
        twitter: 'memes',
      },
    ],
    posts: [],
  },
  // B I L L  G A T E S
  {
    id: 15,
    name: 'Bill Gates',
    birthday: 'October 28',
    verified: true,
    username: 'gates',
    // eslint-disable-next-line
      photo: require('./img/users/gates.png'),
    // eslint-disable-next-line
      background: require('./img/bg/gates.png'),
    desc: 'Sharing things Im learning through my foundation work and other interests.',
    about: 'Businessman',
    followers: '3,4m',
    community: false,
    recommended: false,
    category: ['Entrepreneur'],
    social: [
      {
        website: 'gatesnotes.com/',
      },
      {
        twitter: 'BillGates',
      },
    ],
    posts: [],
  },
  // P Y R O K I N S I S
  {
    id: 16,
    name: 'Andrii Fedorovych',
    birthday: 'December 16',
    verified: true,
    username: 'pyrokinesis',
    // eslint-disable-next-line
    photo: require('./img/users/pyrokinesis.png'),
    // eslint-disable-next-line
    background: require('./img/bg/pyrokinesis.png'),
    desc: 'don\'t answer the mirror\'s questions',
    about: 'Rapper',
    followers: '137,8k',
    community: false,
    recommended: false,
    category: ['Musician'],
    social: [
      {
        instagram: 'andrushapyro',
      },
      {
        twitter: 'pyrokinezis',
      },
    ],
    posts: [],
  },
  // Z E L E N S K Y
  {
    id: 17,
    name: 'Volodymyr Zelensky',
    birthday: 'January 25',
    verified: true,
    username: 'zelensky',
    // eslint-disable-next-line
    photo: require('./img/users/zelensky.png'),
    // eslint-disable-next-line
    background: require('./img/bg/zelensky.png'),
    desc: 'Президент України / President of Ukraine',
    about: 'President of Ukraine',
    followers: '589,1k',
    community: false,
    recommended: false,
    category: ['Politician'],
    social: [
      {
        instagram: 'zelenskyy_official',
      },
      {
        twitter: 'ZelenskyyUa',
      },
      {
        website: 'president.gov.ua',
      },
    ],
    posts: [],
  },
  // C H B D
  {
    id: 18,
    name: 'chbd',
    verified: true,
    username: 'chdbchdb',
    // eslint-disable-next-line
      photo: require('./img/users/chbd.png'),
    // eslint-disable-next-line
      background: require('./img/bg/chbd.png'),
    desc: 'YouTube show “What Happened Next”.\nContact via social media and email',
    about: 'YouTube show',
    followers: '1,3m',
    community: true,
    recommended: false,
    category: ['Humor'],
    social: [
      {
        instagram: 'labelcomedians',
      },
      {
        website: 'president.gov.ua',
      },
    ],
    posts: [],
  },
  // Z E L E N S K Y
  {
    id: 19,
    name: 'Josana González',
    birthday: 'February 27',
    verified: false,
    username: 'deathburger',
    // eslint-disable-next-line
    photo: require('./img/users/deathburger.png'),
    // eslint-disable-next-line
    background: require('./img/bg/deathburger.png'),
    desc: 'The Future is Now - Cyberpunk Narrative Artbook and merch available at citadel9.com',
    about: 'President of Ukraine',
    followers: '141,5k',
    community: false,
    recommended: false,
    category: ['Blogger'],
    social: [
      {
        instagram: 'deathburger',
      },
      {
        website: 'citadel9.com',
      },
    ],
    posts: [],
  },
]);

posts.value.forEach((post) => {
  const foundUser = users.value.find((currentUser) => currentUser.id === post.userId);
  if (foundUser) {
    foundUser.posts.push(post);
  }
});

export default users;
