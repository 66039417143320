<template>
  <ul class="header">
    <li class="header_left" v-if="!isMobile && !isTablet">
      <div class="header_logo">
        <router-link to="/">
          <img src="../img/logo.svg" alt="logo">
        </router-link>
      </div>
    </li>
    <li class="header_left" v-else-if="(isMobile || isTablet) && isDetailsPage">
      <button @click="$router.back()" class="back">
        <div class="back_icon">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8333M4.16675 10L10.0001 4.16667"
            stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        Go back
      </button>
    </li>
    <li class="header_left" v-else-if="(isMobile || isTablet) && !isDetailsPage">
      <button class="header_menu" @click="toggleMobileSidebar">
        <img src="../img/icons/header/menu.svg" alt="menu">
      </button>
      <div class="header_logo">
        <router-link to="/">
          <img src="../img/logo.svg" alt="logo">
        </router-link>
      </div>
    </li>
    <li class="header_center" v-if="!isMobile">
      <div class="header_search" v-if="!isDetailsPage || isTablet">
        <Search />
      </div>
      <div class="header_back" v-else>
        <button @click="$router.back()" class="back">
          <div class="back_icon">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.8334 10H4.16675M4.16675 10L10.0001 15.8333M4.16675 10L10.0001 4.16667"
              stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          Go back
        </button>
      </div>
    </li>
    <li class="header_actions">
      <button class="btn simple" @click="showLogin = true">
        Log In
      </button>
      <button class="btn black" @click="showJoin = true">
        Join now
      </button>
    </li>
  </ul>
  <PopupLogin
  :title="`Welcome back`"
  :desc="`Please enter your details to sign in.`"
  :color="'blue'"
  :icon="'Login'"
  v-model:show="showLogin"
  @openJoinPopup="openJoinPopup"/>
  <PopupJoin  v-model:show="showJoin" @openLoginPopup="openLoginPopup"/>
</template>
<script>
import { ref, computed, inject } from 'vue';
import { useRoute } from 'vue-router';

import PopupLogin from './popup/PopupLogin.vue';
import PopupJoin from './popup/PopupJoin.vue';
import Search from './Search.vue';

export default {
  name: 'HeaderFixed',
  components: {
    PopupLogin,
    PopupJoin,
    Search,
  },
  setup() {
    const route = useRoute();

    const showLogin = ref(false);
    const showJoin = ref(false);

    const isTabletOpen = inject('isTabletOpen');
    const toggleMobileSidebar = inject('toggleMobileSidebar');
    const isTablet = inject('isTablet');
    const isMobile = inject('isMobile');

    const openLoginPopup = () => {
      showLogin.value = true;
    };

    const openJoinPopup = () => {
      showJoin.value = true;
    };

    const isDetailsPage = computed(() => {
      const routePath = route.path;
      if (routePath.startsWith('/people') || routePath.startsWith('/popular') || routePath.startsWith('/communities')) {
        return false;
      }
      // Перевіряємо, чи це загальна сторінка посту
      const isGeneralPostPage = route.path.startsWith('/post/');
      // Перевіряємо, чи це пост користувача (новий формат username/post/id)
      const isUserPostPage = /^\/[^/]+\/post\/\d+/.test(route.path);
      // Перевіряємо, чи це сторінка профілю користувача
      const isProfilePage = /^\/[^/]+$/.test(route.path);
      return isGeneralPostPage || isUserPostPage || isProfilePage;
    });
    return {
      isDetailsPage,
      showLogin,
      showJoin,
      isTabletOpen,
      toggleMobileSidebar,
      isTablet,
      isMobile,
      openLoginPopup,
      openJoinPopup,
    };
  },
};
</script>
<style lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  height: 6rem;
  padding: 0 1.6rem;
  border-bottom: .06rem solid $border-gray;
  background: $white-bg;
  @media (max-width: 880px) {
    justify-content: space-between;
    padding: 0 1.2rem;
  }
  &_left {
    display: flex;
    align-items: center;
    width: 22rem;
    flex-shrink: 0;
    @media (max-width: 1180px) {
      width: 20rem;
    }
    @media (max-width: 1080px) {
      width: auto;
    }
  }
  &_menu {
    background: none;
    width: 2.4rem;
    height: 2.4rem;
    img {
      width: 100%;
    }
  }
  &_center {
    flex: 1;
    max-width: 63.2rem;
    margin: 0 2rem;
    @media (max-width: 1080px) {
      max-width: none;
      flex: 3;
    }
  }
  &_logo {
    padding: 0 1.2rem;
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      img {
        height: 2.8rem;
      }
    }
  }
  &_actions {
    display: flex;
    justify-content: flex-end;
    width: 29rem;
    align-items: center;
    flex-shrink: 0;
    button:first-child {
      margin-right: .8rem;
      @media (max-width: 480px) {
        margin-right: 0;
      }
    }
    @media (max-width: 1080px) {
      width: auto;
    }
  }
}
.back {
  display: flex;
  align-items: center;
  background: none;
  @include text-14-medium;
  color: $text-black;
  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 100%;
    border: .06rem solid $border-gray;
    background-color: $gray-bg;
    margin-right: 1.2rem;
  }
}
</style>
