<template>
  <Popup v-model:show="showPopup">
    <template #content>
      <div class="popup">
        <div class="popup_wrapper">
          <div class="popup_gradient" :class="color"></div>
          <div class="popup_heading">
            <div class="popup_heading-icon" :class="color">
              <img :src="getIconSrc(icon)" alt="icon">
            </div>
            <h3 class="popup_heading-title">{{ title }}</h3>
            <p class="popup_heading-desc">{{ desc }}</p>
          </div>
          <ul class="social">
            <li class="social_item" @click="LoginByApple" @keypress="LoginByApple">
              <img src="../../img/icons/social/apple.svg" alt="apple">
            </li>
            <li class="social_item" @click="LoginByGoogle" @keypress="LoginByGoogle">
              <img src="../../img/icons/social/google.svg" alt="google">
            </li>
            <li class="social_item" @click="LoginByTwitter" @keypress="LoginByTwitter">
              <img src="../../img/icons/social/x.svg" alt="x">
            </li>
          </ul>
          <div v-if="errorSocial" :class="{ 'popup_error': errorSocial }">{{ errorSocial }}</div>
          <div class="popup_separator">
            <span>OR</span>
          </div>
          <div class="popup_form">
            <FormInput label="E-mail address" placeholder="Enter your email..." type="email"
            v-model="email" :isError="emailError"/>
            <FormInput label="Password" placeholder="Enter your password..." type="password"
            v-model="password" :isError="passwordError"/>
            <p v-if="errorMessage" :class="{ 'popup_error': errorMessage }">{{ errorMessage }}</p>
            <button class="btn black" @click="handleSignIn">Sign in</button>
            <div class="popup_form-text">
              Don’t have an account yet?
              <a to="/"
              @click="handleGoToJoinButtonClick"
              @keypress="handleGoToJoinButtonClick"
              >
              Sign up
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Popup>
</template>

<script>
import { ref } from 'vue';
import { useShowPopupLogic, useShowPropDeclaration } from './utilities/showPropLogic';

import Popup from './Popup.vue';
import FormInput from '../form/FormInput.vue';

const icons = {
  // eslint-disable-next-line
  Following: require('../../img/icons/popup/following.svg'),
  // eslint-disable-next-line
  Reactions: require('../../img/icons/popup/react.svg'),
  // eslint-disable-next-line
  Message: require('../../img/icons/popup/message.svg'),
  // eslint-disable-next-line
  Reply: require('../../img/icons/popup/reply.svg'),
  // eslint-disable-next-line
  Like: require('../../img/icons/popup/like.svg'),
  // eslint-disable-next-line
  CreatePost: require('../../img/icons/popup/create.svg'),
  // eslint-disable-next-line
  Follow: require('../../img/icons/popup/follow.svg'),
  // eslint-disable-next-line
  Login: require('../../img/icons/popup/hello.svg'),
  // eslint-disable-next-line
  Share: require('../../img/icons/popup/share.svg'),
};

export default {
  name: 'PopupLogin',
  props: {
    title: {
      type: String,
      default: 'Sign in',
    },
    desc: {
      type: String,
      default: 'Please enter your details to sign in.',
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'Login',
    },
  },
  components: {
    Popup,
    FormInput,
  },
  useShowPropDeclaration,
  emits: ['update:show', 'openJoinPopup'],
  setup(props, { emit }) {
    const showPopup = useShowPopupLogic(props, emit);

    const errorSocial = ref('');

    const LoginByApple = () => {
      errorSocial.value = 'Login with an Apple account is temporarily unavailable.';
    };
    const LoginByGoogle = () => {
      errorSocial.value = 'Login with an Google account is temporarily unavailable.';
    };
    const LoginByTwitter = () => {
      errorSocial.value = 'Login with an Twitter account is temporarily unavailable.';
    };

    const password = ref('');
    const email = ref('');

    const errorMessage = ref('');
    const emailError = ref(false);
    const passwordError = ref(false);

    const handleSignIn = () => {
      errorMessage.value = '';
      emailError.value = false;
      passwordError.value = false;

      if (!email.value || email.value.trim() === '') {
        errorMessage.value = 'The Email field cannot be empty.';
        emailError.value = true;
        return;
      }
      if (!password.value || password.value.trim() === '') {
        errorMessage.value = 'The Password field cannot be empty.';
        passwordError.value = true;
        return;
      }
      errorMessage.value = 'Account not found. Please check your email and password.';
    };

    const handleGoToJoinButtonClick = () => {
      showPopup.value = false;
      emit('openJoinPopup');
    };

    const getIconSrc = (icon) => icons[icon] || icons.Login;
    return {
      showPopup,
      getIconSrc,
      handleGoToJoinButtonClick,
      handleSignIn,
      LoginByApple,
      LoginByGoogle,
      LoginByTwitter,
      email,
      password,
      errorSocial,
      errorMessage,
      emailError,
      passwordError,
    };
  },
};

</script>

<style scoped lang="scss">
.popup_form {
  .btn {
    margin-top: 1.6rem;
  }
}
.social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  &_item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.4rem;
    width: calc(100% / 3);
    border: .1rem solid $border-gray;
    border-radius: .8rem;
    cursor: pointer;
    transition: .5s;
    &:hover {
      opacity: .6;
      transition: .5s;
    }
    img {
      width: 2rem;
    }
  }
}
</style>
